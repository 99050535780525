import React from 'react';
import { FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import styles from '../styles/components/Footer.module.css';

const Footer = () => {
  // const scrollToTop = () => {
  //   window.scrollTo({ top: 0, behavior: 'smooth' });
  // };

  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.section}>
          <h3>JOMS</h3>
          <p>
            JOMS was born out of the shared vision of passionate individuals who believe in the power of innovation to make a difference.
          </p>
        </div>

        <div className={styles.section}>
          <h3>Subscribe to Our Newsletter</h3>
          <p>Get the latest updates, tips, and insights directly to your inbox.</p>
          <form className={styles.form}>
            <input type="email" placeholder="Enter your email" required />
            <button type="submit">Subscribe</button>
          </form>
        </div>

        <div className={styles.section}>
          <h3>Quick Links</h3>
          <ul className={styles.links}>
            <li><a href="#aboutus">About Us</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
            <li><a href="/terms-of-service">Terms of Service</a></li>
            <li><a href="#contactus">Contact Us</a></li>
          </ul>
        </div>
      </div>

      <div className={styles.copy}>
        <p>© 2024 JOMS. All Rights Reserved.</p>
        <div className={styles.social}>
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn />
          </a>
          <a href="mailto:hello@joms.in">
            <FaEnvelope />
          </a>
        </div>
      </div>

      {/* <button className={styles.scrollUp} onClick={scrollToTop} aria-label="Scroll to top">
        <FaArrowUp />
      </button> */}
    </footer>
  );
};

export default Footer;