import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  FaHandshake,
  FaLightbulb,
} from "react-icons/fa";
import { FiGlobe } from "react-icons/fi";
import { HiPlus, HiMinus } from "react-icons/hi";
import styles from "../styles/components/Vision.module.css";

const roadmapData = [
  {
    icon: <FiGlobe />,
    title: "Global Expansion",
    text: (
      <ul>
        <li>Establishing a presence in emerging markets.</li>
        <li>Building partnerships with international distributors.</li>
        <li>Localizing services to meet cultural and regional needs.</li>
      </ul>
    ),
  },
  {
    icon: <FaHandshake />,
    title: "Partnerships",
    text: (
      <ul>
        <li>Collaborating with non-profits for community programs.</li>
        <li>Forming strategic alliances to boost innovation.</li>
        <li>Engaging with industry leaders for knowledge sharing.</li>
      </ul>
    ),
  },
  {
    icon: <FaLightbulb />,
    title: "Innovation",
    text: (
      <ul>
        <li>Adopting AI and machine learning for enhanced user experience.</li>
        <li>Expanding R&D teams for cutting-edge developments.</li>
        <li>Launching sustainable and eco-friendly solutions.</li>
      </ul>
    ),
  },
];

const Vision = () => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  console.log(expandedIndexes, ": expandedIndexes");
  const toggleAccordion = (index) => {
    setExpandedIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <section id="ourvision" className={styles.container}>
      <h1 className={styles.title}>Our Vision</h1>
      <Accordion allowZeroExpanded>
        {roadmapData.map((step, index) => (
          <AccordionItem key={index} uuid={index}>
            <AccordionItemHeading onClick={() => toggleAccordion(index)}>
              <AccordionItemButton
                className={styles.accordionHeading}
              >
                <span className={styles.icon}>{step.icon}</span>
                <span>{step.title}</span>
                <span className={styles.toggleIcon}>
                  {expandedIndexes.includes(index) ? <HiMinus /> : <HiPlus />}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className={styles.text}>{step.text}</div>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </section>
  );
};

export default Vision;
