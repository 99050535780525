import React, { useEffect, useState } from "react";
import Preloader from "./components/Preloader";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Story from "./components/Story";
import Impact from "./components/Impact";
import Vision from "./components/Vision";
import ContactUs from "./components/ContactUs";
import Products from "./components/Products";
// import JoinUs from "./components/JoinUs";
import Footer from "./components/Footer";
import styles from "./styles/App.module.css";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = () => {
      setTimeout(() => setLoading(false), 3000);
    };

    loadData();
  }, []);

  return (
    <div>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div className={styles.view}>
            <Navbar />
            <Hero />
          </div>
          <main>
            <AboutUs />
            <Story />
            <Impact />
            <Vision />
            <Products />
            {/* <JoinUs /> */}
            <ContactUs />
          </main>
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
